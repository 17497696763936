/* stylelint-disable no-eol-whitespace */
/* stylelint-disable rule-empty-line-before */
/* stylelint-disable shorthand-property-no-redundant-values */
/* stylelint-disable selector-class-pattern */
@media (min-resolution: 144dpi) and (min-width: 992px) {
  .index-numbers-block .number span,
  .index-status-small .text p span {
    font-size: 1.8rem !important;
  }

  /** node **/
  .node-energySource {
    width: 110px;
    height: 110px;
  }

  .node-taipower {
    width: 80px;
  }

  .node-ess {
    width: 55px;
  }

  .node-solar {
    width: 80px;
  }

  .node-load {
    width: 80px;
  }

  .node-charger {
    width: 35px;
  }

  .node-wind {
    width: 45px;
  }
}

@media (max-width: 1440px) {
  .index-status-small .chart {
    display: none;
  }

  .index-numbers-block .number span {
    font-size: 2rem;
  }

  .custom-piechart {
    height: auto;
    width: 100%;
    display: block;

    & .recharts-wrapper {
      display: none;
    }

    & .label {
      position: static;
    }

    & .wrap {
      align-items: flex-start;
    }
  }

  .alert-list-items {
    justify-content: flex-start
  }

  .node-energySource-d {
    width: 70px;
    height: 70px;
  }

  .node-taipower-d {
    width: 55px;
  }

  .node-ess-d {
    width: 37px;
  }

  .node-solar-d {
    width: 50px;
  }

  .node-load-d {
    width: 55px;
  }

  .node-charger-d {
    width: 17px;
  }

  .node-wind-d {
    width: 22px;
  }
}

@media (max-width: 1199px) {
  .lockman {
    display: none;
  }

  .index-first-topology {
    & > label {
      display: none;
    }
  }

  .time-zone {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .time-slider {
    width: 100%;
  }

  .index-first-select-end {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .green-dashboard-row-top, .green-dashboard-row-top-2 {
    height: auto;
    overflow: visible;
  
    & > .ant-col {
      height: auto;
    }
  }
  
  .green-dashboard-row-bottom, .green-dashboard-row-bottom-2 {
    height: auto;
    overflow: visible;
    
    & > .ant-col {
      height: auto;
    }
  }

  .app-green-report-content + .app-footer {
    display: block;
  }

  .app-green-report-table .ant-table-body {
    max-height: fit-content !important;
  }

  .green-dashboard-row-left .ant-col,
  .green-dashboard-row-left-4row .ant-col,
  .green-dashboard-row-left-3row .ant-col {
    height: auto;
  }

  .green-rwdchart {
    height: 250px !important;
  }
}

@media (max-width: 991px) {
  .index-first-date {
    display: none;
  }

  .time-slider .ant-slider-dot:nth-child(2n),
  .time-slider .ant-slider-mark-text:nth-child(2n) {
    display: none;
  }

  .dashboard-charts-card {
    height: auto;
  
    & .ant-card-body {
      height: auto;
    }
  }

  .dashboard-row-top {
    height: auto;
    overflow: visible;
  
    & > .ant-col {
      height: auto;
    }
  }
  
  .dashboard-row-bottom {
    height: auto;
    overflow: visible;
    
    & > .ant-col {
      height: auto;
    }
  }

  .app-content-dashboard + .app-footer {
    display: block;
  }

  .dashboard-flow {
    height: 300px !important;
  }

  .rwdchart {
    height: 250px !important;
  }

  /** react-grid-layout **/
  .react-grid-layout {
    height: auto !important;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    gap: 16px;
    padding: 16px;
  }

  .app-analysis-btns {
    display: none;
  }

  .react-grid-layout .ant-card {
    cursor: pointer;
  }

  .react-grid-item {
    width: calc(50% - 8px) !important;
    height: 310px !important;
    position: static !important;
    transform: none !important;
  }
}

@media (max-width: 767px) {
  .index-section-title + .ant-btn {
    display: none !important;
  }

  .react-grid-item {
    width: 100% !important;
  }
    
  .grid-item-1 {
    order: 1;
  }
    
  .grid-item-2 {
    order: 2;
  }
    
  .grid-item-3 {
    order: 3;
  }
    
  .grid-item-4 {
    order: 4;
  }
    
  .grid-item-5 {
    order: 5;
  }
    
  .grid-item-6 {
    order: 6;
  }
    
  .grid-item-7 {
    order: 7;
  }
    
  .grid-item-8 {
    order: 8;
  }
    
  .grid-item-9 {
    order: 9;
  }
    
  .grid-item-10 {
    order: 10;
  }
    
  .notfound-img {
    width: 70vw;
  }

  .index-first-date {
    text-align: left;
  }

  .app-header-note {
    display: none;
  }

  .ant-layout-sider-collapsed + .ant-layout .app-footer {
    margin-left: 0;
  }

  .app-header {
    margin: 0;
    border-radius: 0;
    background-color: @background-color-lightdark;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
    border-left: 0;
  }

  .app-content {
    margin: 9.6rem 1.6rem 0 1.6rem;
  }

  /** sidebar collapsed **/
  .ant-layout-sider-collapsed + .ant-layout .app-content {
    margin: 8.8rem 2rem 2rem 2rem;
  }

  .ant-layout-sider-collapsed + .ant-layout .app-header {
    margin: 0;
  }

  /** node **/
  .node-energySource {
    width: 100px;
    height: 100px;
  }

  .node-taipower {
    width: 80px;
  }

  .node-ess {
    width: 60px;
  }

  .node-solar {
    width: 80px;
  }

  .node-load {
    width: 80px;
  }

  .node-charger {
    width: 30px;
  }

  .node-wind {
    width: 45px;
  }

  .node-energySource-d {
    width: 60px;
    height: 60px;
  }

  .node-taipower-d {
    width: 45px;
  }

  .node-ess-d {
    width: 30px;
  }

  .node-solar-d {
    width: 40px;
  }

  .node-load-d {
    width: 45px;
  }

  .node-charger-d {
    width: 14px;
  }

  .node-wind-d {
    width: 17px;
  }

  .node-all .text .title {
    display: block;
  }

  .app-login-form {
    margin: 0 auto;
  }

  .app-login-header .menu ol {
    display: none;
  }

  .ant-layout-header {
    padding: 0 20px;
    height: 64px;
    line-height: 64px;
  }

  .loading {
    margin-left: 0;
  }

  .app-aside {
    display: none;
  }

  .app-header-logo {
    display: block;
  }

  .app-header-right-menu .user {
    display: none;
  }

  .app-header-mobile-menu {
    width: 26px;
    height: 20px;
    cursor: pointer;
    display: block;
    z-index: 100;

    & em {
      display: block;
      width: 100%;
      height: 2px;
      background: @text-color-light-8;
      margin-top: 6px;
      transition: all 0.3s;
      transition-delay: 0.2s;
    }

    &:first-child {
      margin-top: 0;
    }

    &.open {
      height: auto;

      & em {
        background-color: #fff !important;

        &:nth-child(1) {
          transform: translateY(4px) rotate(45deg);
        }

        &:nth-child(2) {
          transform: translateY(-4px) rotate(-45deg);
        }
      }
    }

    &.open + .header-menu {
      transform: translateX(0%);

      & .header-rightline {
        display: none;
      }

      & li::after {
        transform: scaleX(100%);
      }
    }
  }
}

@media (max-width: 575px) {
  /** node **/
  .node-energySource {
    width: 80px;
    height: 80px;
  }

  .node-all .text .big {
    font-size: 20px;
  }

  .node-taipower {
    width: 70px;
  }

  .node-ess {
    width: 50px;
  }

  .node-solar {
    width: 70px;
  }

  .node-load {
    width: 70px;
  }

  .node-charger {
    width: 20px;
  }

  .node-wind {
    width: 40px;
  }

  .index-section-title {
    margin-bottom: 0.8rem;
  }

  .index-money-saving-text {
    font-size: 1.6rem;
  }

  .app-card-actionItems {
    flex-direction: column;
    align-items: flex-end;
  }

  .app-device-subtitle {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;

    & .ant-divider {
      display: none;
    }
  }

  .app-form-list {
    & .ant-descriptions-item-container {
      flex-direction: column;
    }
  }

  .app-device-chart {
    height: 220px;
  }

  .app-descriptions .ant-descriptions-item-container {
    flex-direction: column;
  }

  .app-descriptions .ant-descriptions-item-label {
    justify-content: flex-start;
    max-width: 100%;
  }
}
