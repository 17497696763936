/* stylelint-disable number-max-precision */
/* stylelint-disable string-quotes */
/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
/* stylelint-disable number-leading-zero */
/* stylelint-disable rule-empty-line-before */
/* stylelint-disable length-zero-no-unit */
/* stylelint-disable shorthand-property-no-redundant-values */
/* stylelint-disable no-invalid-double-slash-comments */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable color-hex-case */
/* stylelint-disable selector-class-pattern */
html {
  font-size: 62.5%;
}

body {
  min-height: 100vh;
  height: auto;
}

/** color system **/
.color-primary {
  color: @primary-color !important;
}

.color-success {
  color: @success-color !important;
}

.color-warning {
  color: @warning-color !important;
}

.color-error {
  color: @error-color !important;
}

.color-light-4 {
  color: rgb(0 0 0 / 40%) !important;
}

.color-light-6 {
  color: rgb(0 0 0 / 60%) !important;
}

/** app-tag **/
.app-tag {
  line-height: 1;
  font-size: 12px;
  padding: 0px 6px;
  border-radius: @border-radius-base;
  color: @primary-color;
  border: 1px solid @primary-color;

  & > .anticon {
    margin-right: 4px;
  }
}

/** grid system **/
.container {
  width: 100%;
  max-width: 1032px;
  padding: 0 16px;
  margin: 0 auto;
}

.app-layout {
  min-height: 100vh;
  overflow: hidden;
}

.app-background {
  background-color: #F4F5F7;
}

.app-simple-logo {
  padding: 1.6rem 0;
  line-height: 1;
}

.app-header {
  margin: 0 0 0 20rem;
  padding: 0 2rem 0 2rem;
  border-radius: 0;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  box-shadow: inset 2px 0px 3px rgba(0, 0, 0, 0.03), 0px 2px 3px rgba(0, 0, 0, 0.03);
  transition: all 0.3s;
}

.ant-layout-header {
  background-color: #fff;
  height: 50px;
  line-height: 50px;
}

.app-simple-header {
  height: auto;
  background-color: @background-color-lightdark !important;
}

.app-header-logo {
  display: none;
}

.app-header-right-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;

  & li + li {
    margin-left: 0.8rem;
  }

  & .user {
    cursor: pointer;

    & .name {
      margin-left: 0.8rem;
    }
  }
}

.app-header-menu {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.app-logo-wrap {
  overflow: hidden;
  margin-bottom: 20px;
}

.app-logo {
  padding: 1.6rem 2.6rem;
  transition: all 0.2s;
  margin: 0;
  overflow: hidden;
  background-color: #fff;
  width: 200px;
  max-width: 100%;

  & > a {
    display: block;
  }

  &.app-logo-collapsed {
    width: 80px;
    padding: 1.6rem;

    & img {
      max-width: 100%;
    }
  }
}

.ant-layout-sider-collapsed {
  & .app-logo {
    margin: 1.6rem;
    text-align: center;
  }
}

.ant-layout-sider-collapsed + .ant-layout .app-content {
  margin: 7rem 2rem 2rem 8rem;
}

.ant-layout-sider-collapsed + .ant-layout .app-header {
  margin: 0 0 0 6rem;
}

.app-header-mobile-menu {
  display: none;
}

.app-breadcrumb {
  margin-bottom: 2rem;
}

.app-aside {
  overflow: 'auto';
  height: '100vh';
  position: 'fixed' !important;
  left: 0;
  top: 0;
  bottom: 0;

  & .app-aside-menu {
    padding: 0 10px;
  }
}

.app-aside-collapsed {
  position: absolute;
  right: -10px;
  bottom: 20px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: @primary-color;
  border-radius: 50%;
}

.app-content-dashboard .ant-row + .ant-row {
  margin-top: 8px;
}

.app-content-dashboard + .app-footer {
  display: none;
}

// .app-content-dashboard .ant-card {
//   overflow: auto;
// }

.app-footer {
  font-size: @font-size-small;
  text-align: center;
  margin-left: 20rem;
  transition: .3s;
}

/** 登入頁 **/
.app-login {
  min-height: 100vh;
  background-color: #00202b;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app-login-header {
  width: 100%;
  padding: 3rem 0;
}

.app-login-header-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .menu {
    display: flex;
    gap: 16px;

    & ol {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      justify-content: flex-end;

      & .ant-btn-link {
        color: @text-color-light-8;
      }
    }
  }
}

.app-login-form {
  margin-left: auto;
  max-width: 350px;
  width: 100%;

  & h1 {
    text-align: center;
    margin-bottom: 4rem;
    color: @text-color-light-8;
  }

  & .ant-checkbox-wrapper {
    color: @text-color-light-8;
  }

  & .ant-input,
  & .ant-input-affix-wrapper {
    background-color: transparent;
    border: 1px solid @text-color-light-2;
    color: @text-color-light-8;
  }

  & .ant-input-affix-wrapper > .ant-input {
    border: none;
  }

  & .login-form-forgot {
    float: right;
    color: @primary-color;
  }

  & .ant-input-password-icon {
    color: @text-color-light-6;

    &:hover {
      color: @text-color-light-8;
    }
  }
}

.app-login-footer {
  align-self: flex-end;
  text-align: center;
  color: rgb(255 255 255 / 30%);
  width: 100%;
}

/** 首頁 **/
.index-first-select-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  & .date {
    color: rgb(0 0 0 / 85%);
  }

  & .time-tick {
    color: rgb(0 0 0 / 85%);
    font-weight: 500;
    margin-left: 4px;
  }

  & .memo {
    color: rgb(0 0 0 / 45%);
    margin-left: 6px;
    font-size: 12px;
  }
}

.index-first-select {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-wrap: wrap;
}

.index-first-topology {
  & label {
    margin-right: 1.4rem;
    max-width: 100px;
  }
}

.index-section-title {
  margin-bottom: 0;
}

.index-first-date {
  text-align: right;
  display: flex;
  align-items: center;

  & .time {
    font-size: 2.4rem;
    font-weight: 500;
    margin-left: 0.4rem;
  }
}

.index-flow-charts {
  height: 460px;
  width: 100%;
}

.index-numbers-block {
  &.border {
    border: 1px solid rgba(0, 0, 0, 0.06);
    padding: 6px;
    border-radius: @border-radius-base;
  }

  & .ant-divider-horizontal {
    margin: 8px 0;
  }

  & h6 {
    font-size: 1.2rem;
    margin-bottom: 0;
    white-space: nowrap;
  }

  & .number {
    margin-bottom: 0;
    font-size: 1.2rem;
    word-wrap: break-word;
    overflow-wrap: break-word;

    & span {
      font-weight: 500;
      font-size: 2.2rem;
    }
  }

  & .bottom-text {
    font-size: 1.2rem;
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.6);
  }

  & .ascent {
    color: @cyan-6;
  }

  & .decline {
    color: @volcano-6;
  }
}

.index-numbers-block + .index-numbers-block {
  margin-top: 16px;
}

.index-flow {
  display: flex;
}

.index-status-small {
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;

  & .chart {
    width: 25%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    & .icon {
      font-size: 40px;
      text-align: center;
      color: @primary-color;
      line-height: 1;
    }
  }

  & .text {
    margin-left: 8px;

    & h6 {
      font-size: 1.2rem;
      margin-bottom: 0;
    }

    & p {
      font-size: 1.2rem;
      margin-bottom: 0;

      & span {
        font-size: 2rem;
        font-weight: 500;
      }
    }
  }
}

.index-status-large {
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;

  & .chart {
    width: 50%;
    height: 100px;
  }

  & .text {
    margin-left: 8px;

    & h6 {
      font-size: 1.2rem;
      margin-bottom: 0;
      white-space: nowrap;
    }

    & p {
      font-size: 1.4rem;
      margin-bottom: 0;

      & span {
        font-size: 2.8rem;
        font-weight: 500;
      }
    }

    & .pie-type {
      list-style: none;
      margin: 0;
      padding: 0;

      & li {
        font-size: 1.2rem;

        & span {
          display: inline-block;
          width: 8px;
          height: 8px;
          background-color: darkgrey;
          margin-right: 8px;
          border-radius: 50%;

          &.type1 {
            background-color: #009CCD;
          }

          &.type2 {
            background-color: #5CB74D;
          }

          &.type3 {
            background-color: #9CF9A6;
          }
        }
      }
    }
  }
}

.index-card {
  height: 100%;
}

.index-money-saving-text {
  font-size: 1.6rem;
  white-space: nowrap;
  overflow: hidden;

  & span {
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.4);
  }
}

/** node **/
.node-energySource {
  position: relative;
  width: 165px;
  height: 165px;
  border-radius: 50%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.node-energySource-d {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
}

.node-taipower {
  width: 120px;
}

.node-taipower-d {
  width: 60px;
}

.node-wind {
  width: 55px;
}

.node-wind-d {
  width: 22px;
}

.node-ess {
  width: 80px;
}

.node-ess-d {
  width: 40px;
}

.node-solar {
  width: 110px;
}

.node-solar-d {
  width: 55px;
}

.node-load {
  width: 120px;
}

.node-load-d {
  width: 60px;
}

.node-charger {
  width: 46px;
}

.node-charger-d {
  width: 22px;
}

.node-all {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & img {
    width: 100%;
    margin-bottom: 5px;
  }

  & .text {
    text-align: center;
    line-height: 1;
    font-size: 12px;
    white-space: nowrap;

    & .big {
      color: @fourth-color;
      font-size: 28px;
      font-weight: 500;
    }

    & .small {
      color: @fourth-color;
      font-size: 18px;
      font-weight: 500;
    }
  }
}

/** 404 **/
.notfound {
  text-align: center;
  padding-top: 4rem;
}

.notfound-img {
  width: 38vw;
  opacity: 0.6;
  margin: 0 auto;
}

.notfound-text {
  text-align: center;

  & h1 {
    font-size: 4.8rem;
    margin-bottom: 1rem;
  }

  & p {
    margin-bottom: 3rem;
  }
}

/** 修改密碼 **/
.changepassword {
  background-position: 70% 70%;
  background-repeat: no-repeat;
}

.lockman {
  position: absolute;
  right: 5vw;
  bottom: 14vh;
  z-index: -1;

  & img {
    opacity: 0.6;
    width: 28vw;
  }
}

/** loading **/
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 50px);
}

.list-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  & .list-title {
    display: flex;
    align-items: center;
  }
}

/* react flow */
.react-flow__panel {
  display: none;
}

.apexcharts-svg {
  background-color: transparent !important;
}

.recharts-default-tooltip {
  color: #7c7c7c;
  border-radius: @border-radius-base;
  border-color: @border-color-base !important;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.15);
}

.chart-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
}

.chart-header-title {
  font-size: @font-size-base;
  font-weight: 500;
}

.chart-header-zoomout {
  cursor: pointer;
  display: inline-block;
}

.recharts-legend-text {
  color: @text-color;
}

.recharts-reference-area-rect,
.recharts-tooltip-cursor {
  fill: @primary-color;
  fill-opacity: 0.15;
}

// loading spin
.loading-spin {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}

//無資料
.nodata {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(0 0 0 / 20%);

  & .anticon {
    font-size: 3rem;
  }

  & p {
    font-size: 12px;
    margin-bottom: 0;
  }
}

// flow
.react-flow__edge path {
  stroke-dasharray: 5;
}

//react-grid-layout
.react-grid-layout {
  position: relative;
  transition: height 100ms ease;
}

.react-grid-item {
  display: block;
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.top-layout .react-draggable {
  cursor: move;
  border-radius: @border-radius-base;
  animation: drag 1s linear infinite;
}

.react-grid-item.react-grid-placeholder {
  display: block;
  background: @primary-color;
  opacity: 0.1;
  border-radius: @border-radius-base;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;

  &::after {
    content: '';
    width: 2px;
    height: 6px;
    background-color: rgba(155, 155, 155, 0.2);
    position: absolute;
    right: 5px;
    bottom: 5px;
  }
  &::before {
    content: '';
    width: 4px;
    height: 2px;
    background-color: rgba(155, 155, 155, 0.2);
    position: absolute;
    right: 7px;
    bottom: 5px;
  }
}

.react-resizable-hide {
  & .react-resizable-handle {
    display: none;
  }
}

// loading
.loading-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  height: 100%;
}
.wave {
  width: 4px;
  height: 20px;
  background: rgba(155, 155, 155, 0.4);
  margin: 1px;
  animation: wave 0.8s linear infinite;
  border-radius: 10px;
}
.wave:nth-child(2) {
  animation-delay: -0.1s;
}
.wave:nth-child(3) {
  animation-delay: -0.2s;
}
.wave:nth-child(4) {
  animation-delay: -0.3s;
}
.wave:nth-child(5) {
  animation-delay: -0.4s;
}
.wave:nth-child(6) {
  animation-delay: -0.5s;
}
.wave:nth-child(7) {
  animation-delay: -0.6s;
}
.wave:nth-child(8) {
  animation-delay: -0.7s;
}
.wave:nth-child(9) {
  animation-delay: -0.8s;
}
.wave:nth-child(10) {
  animation-delay: -0.9s;
}

@keyframes wave {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes drag {
  0% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(1.3);
  }
  100% {
    filter: brightness(1);
  }
}

.react-grid-layout .ant-card {
  & .ant-card-body {
    position: relative;
    height: calc(100% - 40px);
  }

  &:has(.ant-select) .ant-card-body {
    height: calc(100% - 50px);
  }
}

.react-draggable {
  cursor: move;
}

.app-page-content {
  margin-top: 1.2em;
}

.app-form-list-item {
  display: flex;
  align-items: center;

  > label {
    width: 100%;
    max-width: 24%;
    text-align: right;
    padding-right: 12px;
  }
}

.app-form-list-img {
  margin-top: 24px;
  padding-right: 24px;
  text-align: right;
}

.app-card-actionItems {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.app-datePicker-custom {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #d9d9d9;
  border-radius: @border-radius-base;
  background-color: #fff;
  padding: 0px 6px;
  gap: 6px;

  & .ant-picker {
    border: none;
    box-shadow: none;
  }

  & .action-item {
    color: rgba(0, 0, 0, 0.3);
    font-size: 14px;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.1);
    }
  }

  & .disabled .anticon{
    color: rgba(0, 0, 0, 0.1);
 }
}

.app-device-subtitle {
  display: flex;
  align-items: center;
  gap: 16px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 24px;

  & span {
    font-size: 18px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }
}

.app-device-chart {
  height: 325px;
}

.alert-icon {
  margin-right: 6px;
}

.alert-icon-level1 {
  color: #FF4D4F;
}

.alert-icon-level2 {
  color: #FD5E04;
}

.alert-icon-level3 {
  color: #FFA800;
}

.alert-icon-level4 {
  color: @primary-color;
}

.app-card-actions {
  margin-bottom: 8px;
}

.ant-menu-title-content .number,
.ant-drawer-body .number {
  float: right;
  color: rgba(0, 0, 0, 0.4);
}

.ant-descriptions-item-content {
  & pre {
    text-wrap: wrap;
    margin-bottom: 0;
  }
}

.card-header-time {
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;

  & .anticon {
    margin-right: 4px;
  }
}

.ant-menu-item-icon .ant-badge-count {
  display: none;
}

.ant-layout-sider-collapsed .ant-menu-item-icon .ant-badge-count {
  display: block;
  transform: translate(50%, 50%);
}

.ant-tooltip .ant-badge {
  color: #fff;
}

.ant-menu-item.ant-menu-item-selected .ant-badge {
  color: @primary-color !important;
}

.ant-layout-sider-collapsed + .ant-layout .app-footer {
  margin-left: 6rem;
}

.custom-piechart {
  position: relative;
  height: '100%';
  display: inline-block;

  & .label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  & .wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;

    & .title {
      font-size: 12px;
      line-height: 1.2;
      color: rgba(0, 0, 0, 0.6);
    }

    & .values {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.2;
    }
  }

  & p {
    margin-bottom: 0;
  }
}

.custom-piechart-descripition {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);

  & .ant-badge-status-text {
    font-size: 12px;
  }

  & p {
    margin-bottom: 0;
  }
}

.line-height-custom {
  line-height: 1.4;
}

.alert-list-items {
  list-style: none;
  display: flex;
  font-size: 12px;
  margin: 0;
  padding: 0;
  justify-content: flex-end;
  color: rgb(0 0 0 / 40%);

  & li > span,
  & li > a {
    color: @secondary-color;
    font-size: 14px;
    padding-left: 4px;
  }

  & li > span.hasalert,
  & li > a.hasalert {
    color: @alert-color;
  }

  & li + li::before {
    content: '|';
    display: inline-block;
    margin: 0 6px;
    color: rgb(0 0 0 / 10%);
  }
}

.app-table-small {
  & .ant-table-cell {
    padding: 2px !important;
    font-size: 12px !important;
  }
}

.app-table-small .ant-empty-normal {
  margin: 16px 0;
}

.app-table-small a {
  color: rgba(0,0,0,.85);
}

.dashboard-main-chart {
  height: 100%;
}

.dashboard-charts {
  height: 100%;
}

.dashboard-charts-card {
  height: 100%;

  & .ant-card-body {
    height: calc(100% - 41px);
  }
}

.dashboard-flow {
  height: 100%;
  width: 100%;
}

.dashboard-row-top {
  height: calc(50vh - 69px);

  & > .ant-col {
    min-height: 100%;
    height: 100%;
  }
}

.dashboard-row-bottom {
  height: calc(50vh - 69px);

  & > .ant-col {
    min-height: 100%;
    height: 100%;
  }
}

.app-notice a,
.app-notice a:hover,
.app-notice a:active {
  text-decoration: underline;
}

.stage-name {
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
  font-size: 16px;
  letter-spacing: 2px;
}

.app-login-form h1 + .stage-name {
  margin-top: -40px;
  margin-bottom: 40px;
}

.confirm-textList {
  font-size: 18px;
  & span {
    color: @warning-color;
  }
}

.app-descriptions {
  margin: 50px 0;
}

.app-descriptions .ant-descriptions-row > th,
.app-descriptions .ant-descriptions-row > td {
  padding-bottom: 20px;
}

.app-readonly-form .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.app-readonly-form .ant-picker-input > input[disabled],
.app-readonly-form .ant-input-number-disabled {
  color: @text-color;
}

.app-descriptions .ant-descriptions-item-content {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0px 4px 4px 4px;
}

.app-descriptions .ant-descriptions-item-label {
  max-width: 29.16666667%;
  width: 100%;
  justify-content: flex-end;
}

.app-card-extra-btn-group {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}

.ant-card-head-title > .anticon {
  color: rgb(0 0 0 / 40%);
}

.btn-close {
  cursor: pointer;
  color: rgb(0 0 0 / 40%);

  &:active {
    color: rgb(0 0 0 / 80%);
  }
}

.btn-upload {
  color: @primary-color !important;
  border-color: @primary-color !important;
}

.referenceArea .recharts-reference-area-rect {
  fill: #000;
  fill-opacity: 0;
}

.referenceArea2 .recharts-reference-area-rect {
  fill: rgba(0, 0, 0, 0.04);
  fill-opacity: 1;
}

.referenceArea .recharts-text,
.referenceArea2 .recharts-text,
.referenceArea_alert .recharts-text{
  fill: rgba(128, 128, 128, 1);
}

.referenceArea_alert .recharts-reference-area-rect {
  fill: #FF5C00;
  fill-opacity: 0.2;
}

.app-analysis-menu {
  color: rgb(0 0 0 / 40%);
  list-style: none;
  padding: 0 10px;
  margin: 0;

  & ul {
    list-style: none;
    padding: 0;
    margin: 10px 0 0 0;
    color: rgb(0 0 0 / 60%);

    & > li {
      cursor: grab;
      transition: .3s;
      margin-bottom: 10px;
      padding: 0px 2px;
      border-radius: 6px;
      & .anticon {
        margin-right: 6px;
      }

      & .anticon-holder {
        margin-right: 4px;
        color: rgb(0 0 0 / 40%);
      }
    }
  }

  & > li {
    padding: 0 16px;
    margin-bottom: 10px;

    & .anticon-down {
      font-size: 10px;
      margin-left: 4px;
    }
  }
}

.app-analysis-btns {
  & .ant-btn + .ant-btn {
    margin-left: 8px;
  }
}

.col-height-50 {
  height: 50%;
}

.dashboard-row-top-demo {
  height: calc(50vh - 81px);
  overflow: hidden;

  & > .ant-col {
    height: 100%;
  }
}

.dashboard-row-bottom-demo {
  height: calc(50vh - 81px);
  overflow: hidden;

  & > .ant-col {
    height: 100%;
  }
}

// timeZone
.time-zone {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
}

.time-speed {
  display: flex;
  gap: 8px;
  margin-right: 16px;
}

.time-text {
  white-space: nowrap;
}

.time-clock {
  font-size: 32px;
  line-height: 1;
}

.time-slider {
  width: 100%;
  max-width: 100%;
}

.ant-slider-track,
.ant-slider-handle {
  transition: all .1s;
}

.ant-btn.ant-btn-background-ghost {
  color: rgba(0, 0, 0, 0.65);
  border-color: rgba(0, 0, 0, 0.65);
}

.ant-slider-rail {
  background-color: rgba(0, 0, 0, 0.05);
}

.app-login-header-menu .ant-btn.ant-btn-background-ghost {
  color: @text-color-light-8;
  border-color: @text-color-light-8;
}

.index-first-select-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  flex-wrap: wrap;
}

/* 修改Chrome浏览器的滚动条样式 */
::-webkit-scrollbar {
  width: 5px; /* 设置滚动条宽度 */
  height: 5px;
  border-radius: 6px;
}

/* 滚动条滑块样式 */
::-webkit-scrollbar-thumb {
  background-color: rgb(155 155 155 / 50%); /* 设置滑块颜色 */
  border-radius: 6px;
}

/* 滚动条背景样式 */
::-webkit-scrollbar-track {
  background-color: transparent; /* 设置背景颜色 */
  border-radius: 6px;
}

/* 修改Edge浏览器的滚动条样式 */
::-ms-scrollbar {
  width: 4px; /* 设置滚动条宽度 */
  border-radius: 6px;
}

/* 滚动条滑块样式 */
::-ms-thumb {
  background-color: transparent; /* 设置滑块颜色 */
  border-radius: 6px;
}

/* 滚动条背景样式 */
::-ms-track {
  background-color: transparent; /* 设置背景颜色 */
  border-radius: 6px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

//統計數值
.logout-count-down .ant-statistic-content {
  font-size: 18px;
}

//首頁文字、icon跟著視窗大小縮小
.app-content {
  margin: 7rem 2rem 0 22rem;
  z-index: 2;
  transition: all 0.3s;
}

.app-content-dashboard,
.app-content-dashboard .ant-tag, 
.app-content-dashboard .ant-select,
.app-content-dashboard .ant-picker,
.app-content-dashboard .ant-picker-input > input,
.app-content-dashboard .ant-btn,
.app-content-dashboard .recharts-legend-text,
.app-content-dashboard .ant-card,
.app-content-dashboard .nodata p{
  font-size: clamp(1.2rem, 0.75vw, 1.4rem) !important;
}

.app-content-dashboard .ant-picker-input > input {
  line-height: 22px;
}

.app-content-dashboard .index-numbers-block .number,
.app-content-dashboard .node-all .text {
  font-size: clamp(1rem, 0.6vw, 1.4rem) !important;
}

.app-content-dashboard .index-numbers-block .number span,
.app-content-dashboard .node-all .text .small{
  font-size: clamp(1.2rem, 1vw, 2rem) !important;
}

.app-content-dashboard .ant-card-small > .ant-card-head {
  font-size: clamp(1.2rem, 0.8vw, 1.6rem) !important;
}

.app-content-dashboard .nodata .anticon {
  font-size: clamp(2rem, 1.5vw, 3rem) !important;
}

.app-content-dashboard .node-taipower-d {
  width: clamp(45px, 3.5vw, 55px) !important;
}

.app-content-dashboard .node-solar-d {
  width: clamp(40px, 3.3vw, 50px) !important;
}

.app-content-dashboard .node-energySource-d {
  width: clamp(60px, 4.5vw, 80px) !important;
  height: clamp(60px, 4.5vw, 80px) !important;
  font-size: clamp(1rem, 0.8vw, 1.4rem) !important;
}

.app-content-dashboard .node-ess-d {
  width: clamp(30px, 2.5vw, 37px) !important;
}

.app-content-dashboard .node-load-d {
  width: clamp(45px, 3.5vw, 55px) !important;
}

.app-content-dashboard .node-charger-d {
  width: clamp(14px, 3.5vw, 17px) !important;
}

.app-content-dashboard .node-wind-d {
  width: clamp(17px, 3.5vw, 22px) !important;
}

.app-content-dashboard .ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: clamp(4px, 2.5vw, 8px) 0;
}

//legend

.recharts-legend-item:has(.opacity) > .recharts-surface {
  opacity: 0.4;
}

.recharts-legend-text,
.recharts-legend-item > .recharts-surface  {
  cursor: pointer;
  transition: .15s;
}

.recharts-legend-text.opacity {
  opacity: 0.4;
}

.recharts-layer path,
.recharts-layer g {
  transition: .15s;
}

.recharts-tooltip-wrapper {
  z-index: 99;
}

.recharts-wrapper {
  overflow: visible !important;
}

//pdf-setting
.pdf-content .ant-card-head {
  padding: 0 8px;
  min-height: auto
}
.pdf-content .ant-card-head-title {
  padding: 8px 0;
}
.pdf-content .ant-card-head,
.pdf-content .ant-table,
.pdf-content .ant-empty {
  font-size: 10px;
}

.ant-card-head-title > span {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}

.line-dash path{
  stroke-dasharray: 3 3;
}

.ant-btn.ant-btn-default:hover, 
.ant-btn.ant-btn-default:focus{
  color: @primary-color !important;
}

.app-charge-point {
  display: flex;
  padding-right: 10px;
}

.app-charge-point .image + .text{
  margin-left: 16px;
}

.app-charge-point .image img {
  width: 21px;
}

.app-charge-point .text p {
  color: rgba(0, 0, 0, 0.6);
  line-height: 1.2;
  margin-bottom: 4px;
}

.app-charge-point .text p > span {
  font-size: 16px;
}

.app-charge-point .text .value {
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  margin-bottom: 2px;
}

.app-charge-point .text .ant-badge-status-text {
  color: rgba(0, 0, 0, 0.6);
}

.app-refLine-time {
  display: inline-block;
}

// 綠電Dashboard
.app-dashboard-item-card {
  height: 100%;
  overflow: hidden;
  position: relative;

  & .ant-card-body {
    height: 100%;
    z-index: 1;
    position: relative;
  }

  & .ant-card-head + .ant-card-body {
    height: calc(100% - 57px);
    z-index: 1;
    position: relative;
  }

  &.ant-card-small .ant-card-head + .ant-card-body {
    height: calc(100% - 38px);
    z-index: 1;
    position: relative;
  }

  &.important::before {
    background-color: rgba(250, 173, 20, 0.08);
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    z-index: 0;
    border: 4px solid rgba(250, 173, 20, 0.08);
  }
  &.important-second::before {
    background-color: rgba(250, 173, 20, 0.02);
    border: 4px solid rgba(250, 173, 20, 0.02);
  }
  &.important-blue::before {
    background-color: rgba(0, 156, 205, 0.08);
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    z-index: 0;
    border: 4px solid rgba(0, 156, 205, 0.08);
  }
  &.important-blue-second::before {
    background-color: rgba(0, 156, 205, 0.02);
    border: 4px solid rgba(0, 156, 205, 0.02);
  }
}

.app-dashboard-item-wrap {
  display: flex;
  padding-left: 1em;
  align-items: center;
  height: 100%;
}

.app-dashboard-item-icon-wrap {
  & .icon {
    width: 36px;
    height: 36px;
    background-color: @primary-color;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    box-shadow: 0px 16px 12px rgba(0,144,226,0.12);
    color: #fff;
    &.key-point {
      background-color: @warning-color;
      box-shadow: 0px 16px 12px rgba(166,129,51,0.12);
    }
  }
}

.app-dashboard-item-text-wrap {
  margin-left: 1.6rem;
  line-height: 1;
  overflow-x: auto;
  & p {
    margin-bottom: clamp(0.4rem, 0.4vw, 0.8rem);
  }
  & .title {
    color: rgba(0, 0, 0, 0.6);
    font-size: clamp(1.2rem, 0.9vw, 1.6rem);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & .value {
    font-size: clamp(2rem, 1.7vw, 3rem);
    font-weight: bold;
  }
  & .unit {
    font-size: 1.2rem;
    font-weight: normal;
  }
  & .description {
    font-size: 1.2rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.6);
  }
}

.app-green-report-content + .app-footer {
  display: none;
}

.green-dashboard-row-top, .green-dashboard-row-bottom {
  height: calc(50vh - 100px);
}

.green-dashboard-row-top > .ant-col,
.green-dashboard-row-top-2 > .ant-col {
  min-height: 100%;
  height: 100%;
}

.green-dashboard-row-top-2 {
  height: calc(40vh - 100px);
}

.green-dashboard-row-bottom-2 {
  height: calc(60vh - 100px);
}

.green-dashboard-row-left {
  height: 100%;
}

.app-dashboard-item-certificate-card {
  height: 100%;
  & .ant-card-body {
    height: 100%;
    padding: 3.2rem;
    &::after {
      content: '';
      position: absolute;
      display: block;
      left: 14px;
      right: 14px;
      top: 14px;
      bottom: 14px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      pointer-events: none;
    }
    &::before {
      content: '';
      position: absolute;
      display: block;
      left: 10px;
      right: 10px;
      top: 10px;
      bottom: 10px;
      border: 2px solid rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      pointer-events: none;
    }
  }
}

.app-dashboard-item-certificate-wrap {
  max-width: 560px;
  margin: 0 auto;
}

.app-dashboard-item-certificate-inner-wrap {
  display: flex;
  gap: 16px;
  justify-content: space-between;
}

.app-dashboard-item-certificate-item {
  line-height: 1;
  text-align: center;
  & .title {
    margin-bottom: 4px;
    font-weight: bold;
    font-size: clamp(1.2rem, 2vw ,1.6rem);
  }
  & .value {
    font-size: clamp(3.6rem, 4vw ,7.2rem);
    color: @primary-color;
    margin-bottom: 4px;
    font-weight: bold;
  }
  & .unit {
    font-size: 1.2rem;
  }
  & .description {
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.4);
  }
}

.app-dashboard-item-certificate-note {
  text-align: center;
  margin-top: 3.2rem;
  padding: 8px 0px;
  border-top: 2px dashed rgba(0, 0, 0, 0.1);
  border-bottom: 2px dashed rgba(0, 0, 0, 0.1);
}

.app-dashboard-item-certificate-image > img {
  max-width: 100%;
  margin: auto;
  display: block;
}

.app-green-report-table {
  height: calc(100% - 30px);
}

.app-green-report-table .ant-spin-container,
.app-green-report-table .ant-table,
.app-green-report-table .ant-spin-nested-loading,
.app-green-report-table .ant-table-container {
  height: 100%;
}

.app-green-report-table .ant-table-body {
  max-height: calc(100% - 32px) !important;
}

.app-green-report-table-id {
  color: @secondary-color;
  border: 1px solid rgba(0, 185, 153, 0.4);
  padding: 0 6px;
  border-radius: 4px;
  background-color: rgba(0, 185, 153, 0.1);
  cursor: pointer;
}

.app-green-report-table-id:hover,
.app-green-report-table-id:active,
.app-green-report-table-id:focus {
  background-color: rgba(0, 185, 153, 0.2);
  color: @secondary-color;
}

.green-dashboard-charts-card {
  height: 100%;
}

.green-dashboard-charts-card .ant-card-body {
  height: calc(100% - 40px);
}

.green-dashboard-charts-card .ant-table-pagination.ant-pagination {
  margin-bottom: 0px;
}

.app-table-custom {
  & .anticon-calendar{
    color: rgba(0, 0, 0, 0.4);
  }
}

.upload-modal-form {
  margin-bottom: 16px;
  padding: 0px 16px;
}

.upload-modal-form-item-label {
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 32px;
}

.upload-modal-form-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: @warning-color;
  text-align: center;
}

.upload-modal-form-item-input {
  width: 100%;

  & .ant-input-number {
    width: 100%;
  }

  & .ant-picker {
    width: 100%;
  }

  & .ant-select {
    width: 100%;
  }

  & .ant-input-number-group-wrapper {
    width: 100%;
  }
}

.upload-modal-form-row-title {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: center;
}

.table-cell-border {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}