/* stylelint-disable block-closing-brace-newline-before */
/* stylelint-disable max-line-length */
/* stylelint-disable color-function-notation */
/* stylelint-disable alpha-value-notation */
/* stylelint-disable selector-not-notation */
/* stylelint-disable no-eol-whitespace */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable rule-empty-line-before */
/* stylelint-disable indentation */
.dark {
    & .index-first-select-menu {     
        & .date {
            color: @text-color-light-8;
        }
      
        & .time-tick {
            color: @text-color-light-8;
        }

        & .memo {
            color: @text-color-light-4;
        }
      }
    & .ant-layout-sider-trigger {
        background-color: @background-color-lightdark;
        color: @text-color-light-6;
    }

    & .app-logo {
        background-color: @background-color-lightdark;
    }
    
    & .app-aside {
        background-color: @background-color-lightdark;
      
        & .app-aside-menu {
          background-color: @background-color-lightdark;
        }
    }
   
    & .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: rgb(0 156 205 / 10%);
    }
    
    & .ant-menu-dark .ant-menu-item-selected > a,
    & .ant-menu-dark .ant-menu-item-selected > span > a,
    & .ant-menu-dark .ant-menu-item-selected > a:hover,
    & .ant-menu-dark .ant-menu-item-selected > span > a:hover {
        color: @primary-color;
    }
    
    & .ant-menu-dark .ant-menu-item-selected,
    & .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
    & .ant-menu-dark .ant-menu-item-selected .anticon,
    & .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
    & .ant-menu-dark .ant-menu-item-selected .anticon + span {
        color: @primary-color;
    }

    & .app-aside-collapsed {
        background-color: @primary-color;
        border: 3px solid @background-color-dark;
    }

    & .ant-card {
        background-color: @background-color-lightdark;
        color: @text-color-light-8;
    }

    & .ant-card-head {
        color: @text-color-light-8;
        border-bottom: 1px solid @border-color-light-05;
    }
    
    & .ant-select {
        color: @text-color-light-8;
    
        & .ant-select-selector {
            background-color: transparent !important;
            border: 1px solid @text-color-light-2 !important;
        
            &:focus,
            &:hover {
                border: 1px solid @text-color-light-4 !important;
            }
        }
    }
    
    & .ant-btn-text {
        color: @text-color-light-8;
    }

    & .ant-btn.ant-btn-default {
        background-color: transparent;
        color: @text-color-light-8;
        border-color: @text-color-light-4;

        &:hover,
        &:focus {
            color: @primary-color;
            border-color: @primary-color;
        }
    }

    & .ant-btn[disabled],
    & .ant-btn[disabled]:hover,
    & .ant-btn[disabled]:focus,
    & .ant-btn[disabled]:active {
        color: @text-color-light-2;
        border-color: @border-color-light-1;
    }

    & .ant-select-arrow {
        color: @text-color-light-2;
    }
    
    & .ant-checkbox-wrapper {
        color: @text-color-light-8;
    }
    
    & .ant-input-password-icon.anticon {
        color: @text-color-light-8;
    }
    
    & .ant-input,
    & .ant-input-affix-wrapper {
        background-color: transparent;
        border: 1px solid @text-color-light-2;
        color: @text-color-light-8;
    }
    
    & .ant-input-affix-wrapper > .ant-input {
        border: none;
    }
    
    & .ant-form-item-label > label {
        color: @text-color-light-8;
    }
    
    & .ant-checkbox-inner {
        background: none;
    }
    
    & .ant-checkbox-checked .ant-checkbox-inner {
        background-color: @primary-color;
    }
    
    & .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
    & .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
    & .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    & .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
        background: none;
    }
    
    /** color system **/
    & .color-light-4 {
        color: rgb(255 255 255 / 40%) !important;
    }

    & .color-light-6 {
        color: rgb(255 255 255 / 60%) !important;
    }

    & .ant-drawer-wrapper-body {
        background-color: @background-color-lightdark;
        color: @text-color-light-8;
    }
    
    & .ant-drawer-header {
        border-bottom: 1px solid @border-color-light-1;
    }
    
    & .ant-drawer-title {
        color: @text-color-light-8;
    }
    
    & .ant-drawer-close {
        color: @text-color-light-6;
    
        &:hover {
            color: @text-color-light-6;
        }
    }
    
    & .ant-drawer-content {
        background: none;
    
        & a {
            color: @text-color-light-8;
        }
        
        & .ant-divider-horizontal {
            border-color: @border-color-light-05;
        }
    }
    
    & .ant-select-dropdown,
    &.ant-select-dropdown {
        background-color: @background-color-lightdark;
        color: @text-color-light-8;
        border: 1px solid @border-color-light-1;
    }
    
    & .ant-select-item {
        color: @text-color-light-8;
    }
    
    & .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: @background-color-lightdark;
        color: #fff;
    }
    
    & .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: @background-color-exlightdark;
        color: @text-color-light-8;
    }

    /* gloabal */ 
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
        color: @text-color-light-8;
    }

    /** grid system **/
    &.app-layout {
        background-color: @background-color-dark;
    }

    /** collapse **/
    & .ant-collapse {
        background-color: @background-color-lightdark;
        color: @text-color-light-6;
        border-color: @border-color-light-05;
    }

    & .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        color: @text-color-light-6;
    }

    & .ant-collapse-content {
        background-color: @background-color-lightdark;
        color: @text-color-light-6;
        border-color: @border-color-light-05;
    }

    & .ant-collapse > .ant-collapse-item {
        border-color: @border-color-light-05;
    }

    /** header **/
    & .app-simple-header {
        background-color: @background-color-lightdark;
        color: @text-color-light-6;
    }

    & .app-header {
        background-color: @background-color-lightdark;
        color: @text-color-light-6;
        border-left: 1px solid rgba(0, 0, 0, 0.2);
    }

    .ant-layout-header {
        background-color: @background-color-lightdark;
    }

    & .app-header-right-menu {
        color: @text-color-light-8;

        & li {
            color: @text-color-light-6;

            & .ant-btn-link {
                color: @text-color-light-6;
            }
        }

        & .user {
            & .name {
                color: @text-color-light-8;
            }
        }
    }

    & .app-breadcrumb {
        color: @text-color-light-4;

        & .ant-breadcrumb-separator {
            color: @text-color-light-4;
        }

        & .ant-breadcrumb-link {
            color: @text-color-light-4;
        }
    }

    & .app-content {
        color: @text-color-light-6;
    }

    & .app-footer {
        background-color: @background-color-dark;
        color: @text-color-light-4;
    }

    & .app-background {
        background-color: @background-color-dark;
    }

    & .ant-dropdown-menu {
        background-color: @background-color-lightdark;
        color: @text-color-light-8;
        border: 1px solid @border-color-light-1;
    }

    & .ant-dropdown-menu-item-divider {
        background-color: @border-color-light-1;
    }

    & .ant-dropdown-menu-item,
    & .ant-dropdown-menu-submenu-title {
        color: @text-color-light-8;
        &:hover {
            background-color: @background-color-exlightdark;
            color: #fff;
        }
    }

    /** 登入頁 **/
    & .app-login {
        background-color: #00202b;
    }

    & .app-login-header-menu {
        & .menu {
            & ol {
                & .ant-btn-link {
                    color: @text-color-light-8;
                }
            }
        }
    }

    & .app-login-form {
        & .login-form-forgot {
            color: @primary-color;
        }
    }

    & .app-login-footer {
        color: rgb(255 255 255 / 30%);
    }

    /** 首頁 **/
    & .index-first-select {
        & label {
            color: @text-color-light-8;
        }
    }

    & .index-numbers-block {
        &.border {
            border-color: rgba(255, 255, 255, 0.06);
        }

        &.lead {
            border-right: 1px solid @border-color-light-05;
        }

        & h6 {
            color: @text-color-light-4;
        }

        & .number {
            color: @text-color-light-8;
        }

        & .bottom-text {
            color: @text-color-light-4;
        }

        & .ascent {
            color: @cyan-6;
        }

        & .decline {
            color: @volcano-6;
        }
    }

    & .index-status-small {
        & .text {
            & h6 {
                color: @text-color-light-4;
            }
        }
    }

    & .index-status-large {
        & .text {
            & h6 {
                color: @text-color-light-4;
            }

            & .pie-type {

                & li {
                    color: @text-color-light-4;

                    & span {
                        background-color: darkgrey;

                        &.type1 {
                            background-color: #009ccd;
                        }

                        &.type2 {
                            background-color: #5cb74d;
                        }

                        &.type3 {
                            background-color: #9cf9a6;
                        }
                    }
                }
            }
        }
    }

    & .index-money-saving-text {
        & span {
            color: @text-color-light-4;
        }
    }

    & .node-all {
        & .text {
            color: @text-color-light-4;

            & .big {
                color: @fourth-color;

                &.zero {
                    color: @text-color-light-4 !important;
                }
            }

            & .small {
                color: @fourth-color;

                &.zero {
                    color: @text-color-light-4 !important;
                }
            }

            & span {
                color: @text-color-light-4;
            }
        }
    }

    & .notfound-text {
        text-align: center;

        & h1 {
            color: @text-color-light-8;
        }

        & p {
            color: @text-color-light-4;
        }
    }

    /** loading **/
    & .loading {
        color: @text-color-light-8;
    }

    & .apexcharts-svg {
        background-color: transparent !important;
    }

    & .recharts-default-tooltip {
        background-color: @background-color-exlightdark !important;
        color: @text-color-light-8;
        border: 1px solid @border-color-light-1 !important;
    }

    & .chart-header-zoomout {
        color: @text-color-light-8;
    }

    & .recharts-legend-text {
        color: @text-color-light-4;
    }

    & .ant-empty-description {
        color: @text-color-light-8;
    }

    & .nodata {
        color: @text-color-light-2;
    }

    & .ant-tabs {
        color: @text-color-light-8;
    }

    & .ant-btn-link {
        color: @primary-color;

        &:active {
            color: @primary-color;
        }
    }

    & .ant-tabs-top > .ant-tabs-nav::before,
    & .ant-tabs-bottom > .ant-tabs-nav::before,
    & .ant-tabs-top > div > .ant-tabs-nav::before,
    & .ant-tabs-bottom > div > .ant-tabs-nav::before {
        border-bottom: 1px solid @border-color-light-1;
    }

    & .ant-dropdown-trigger {
        color: @text-color-light-8;
    }

    & .ant-dropdown-menu,
    & .ant-dropdown-menu-item:hover,
    & .ant-dropdown-menu-submenu-title:hover,
    & .ant-dropdown-menu-item.ant-dropdown-menu-item-active,
    & .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
    & .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
    & .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
        background-color: @background-color-exlightdark !important;
    }

    & .ant-popover {
        color: @text-color-light-8;
        background-color: @background-color-exlightdark !important;
    }

    & .ant-page-header {
        & .ant-breadcrumb {
            color: @text-color-light-8;
        }

        & .ant-breadcrumb-separator {
            color: @text-color-light-4;
        }

        & .ant-breadcrumb a {
            color: @text-color-light-4;
        }

        & .ant-breadcrumb li:last-child {
            color: @text-color-light-8;
        }

        & .ant-page-header-heading-title {
            color: @text-color-light-8;
        }
    }

    & .ant-table {
        background: transparent;
        color: @text-color-light-8;
    }

    & .ant-table-thead > tr > th {
        background: transparent;
        color: @text-color-light-4;
        border-bottom: 1px solid @border-color-light-1;
    }

    & .ant-table-tbody > tr > td {
        border-bottom: 1px solid @border-color-light-1;
    }

    & .ant-table-tbody > tr.ant-table-row:hover > td,
    & .ant-table-tbody > tr > td.ant-table-cell-row-hover {
        background: rgba(255, 255, 255, 0.03);
    }

    & .ant-table-thead > tr > th.ant-table-cell-fix-left,
    & .ant-table-thead > tr > th.ant-table-cell-fix-right,
    & .ant-table-tbody > tr > td.ant-table-cell-fix-left,
    & .ant-table-tbody > tr > td.ant-table-cell-fix-right,
    & .ant-table-tbody > tr > td.ant-table-cell-fix-left.ant-table-cell-row-hover,
    & .ant-table-tbody > tr > td.ant-table-cell-fix-right.ant-table-cell-row-hover{
        background-color: @background-color-lightdark;
        z-index: 2
    }

    & .ant-pagination-item {
        background-color: transparent;
        color: @text-color-light-4;
        border-color: @border-color-light-1;
    }

    & .ant-pagination-item a {
        color: @text-color-light-4;
    }

    & .ant-pagination-prev .ant-pagination-item-link,
    & .ant-pagination-next .ant-pagination-item-link {
        background-color: transparent;
    }

    & .ant-pagination-disabled .ant-pagination-item-link,
    & .ant-pagination-disabled:hover .ant-pagination-item-link {
        color: @text-color-light-4;
        border-color: @border-color-light-1;
    }

    & .ant-pagination-item-active {
        background-color: transparent;
        border-color: @primary-color;
    }

    & .ant-pagination-item-active a {
        color: @primary-color;
    }

    & .ant-pagination-prev button,
    & .ant-pagination-next button {
        color: @text-color-light-4;
        border-color: @border-color-light-1;
    }

    & .ant-empty-img-simple-g {
        stroke: #65676a;
    }

    & .ant-empty-img-simple-path {
        fill: #33373c;
    }
    
    & .ant-empty-img-simple-ellipse {
        fill: rgba(0, 0, 0, 0.1);
    }

    & .ant-page-header-back-button {
        color: @text-color-light-4;
    }

    & .ant-input[disabled] {
        background-color: rgba(0, 0, 0, 0.1);
        color: @text-color-light-6;

        &::placeholder {
            color: @text-color-light-4;
        }

        &:hover {
            border-color: rgba(255, 255, 255, 0.2);
        }
    }

    & .ant-descriptions-title {
        color: @text-color-light-8;
    }

    & .ant-descriptions-item-label {
        color: @text-color-light-4;
    }

    & .ant-descriptions-item-content {
        color: @text-color-light-8;
    }

    & .ant-radio-group {
        color: @text-color-light-8;
    }

    & .ant-radio-button-wrapper {
        border: 1px solid #52585e;
        background-color: transparent;
        color: @text-color-light-8;
    }

    & .ant-radio-button-wrapper:not(:first-child)::before {
        background-color: #52585e;
    }

    & .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        border-color: @primary-color;
        color: @primary-color;
    }

    & .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before,
    & .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: @primary-color;
    }

    & .ant-picker {
        background-color: transparent;
        border-color: rgba(255, 255, 255, 0.2);
    }

    & .ant-picker-suffix {
        color: @text-color-light-4;
    }

    & .ant-picker-input {
        & > input {
            color: @text-color-light-8;
        }
    }

    & .ant-picker-clear {
        background-color: @background-color-lightdark;
        color: @text-color-light-2;
    }

    & .ant-picker.ant-picker-disabled {
        background: rgba(0, 0, 0, 0.05);
        color: @text-color-light-4;
        border-color: rgba(255, 255, 255, 0.2);
    }

    & .ant-picker.ant-picker-disabled .ant-picker-suffix {
        color: @text-color-light-4;
    }

    & .ant-picker-input > input[disabled] {
        color: @text-color-light-4;
        background: rgba(0, 0, 0, 0.05);
    }

    & .app-datePicker-custom {
        border: 1px solid rgba(255, 255, 255, 0.2);
        background-color: transparent;
      
        & .action-item {
          color: @text-color-light-4;
        }

        & .disabled .anticon{
           color: @text-color-light-1;
        }

        & .anticon {
            color: @text-color-light-4;
        }
    }

    & .ant-badge-status-text {
        color: @text-color-light-8;
    }

    & .app-device-subtitle {
        color: @text-color-light-4;
      
        & span {
          color: @text-color-light-8;
        }
    }

    & .ant-divider-vertical {
        border-left: 1px solid @border-color-light-1;
    }

    & .ant-table-tbody > tr.ant-table-placeholder:hover > td {
        background-color: transparent;
    }

    & .ant-badge-status-default {
        background-color: rgb(255 255 255 / 20%);
    }

    & td.ant-table-column-sort {
        background-color: rgb(0 0 0 / 10%);
    }

    & .ant-table-column-sorter,
    & .ant-table-filter-trigger {
        color: @text-color-light-4;
    }

    & .ant-select-multiple .ant-select-selection-item {
        background: @border-color-light-05;
        border-color: @border-color-light-1;
    }

    & .ant-select-multiple .ant-select-selection-item-remove {
        color: @text-color-light-4;
    }

    & .ant-select-clear {
        background: transparent;
        color: @text-color-light-4;
    }

    & .ant-modal-content {
        background-color: @background-color-exlightdark;
        color: @text-color-light-8;
    }

    & .ant-modal-header {
        background-color: @background-color-exlightdark;
        color: @text-color-light-8;
        border-bottom-color: @border-color-light-1;
    }

    & .ant-modal-footer {
        border-top-color: @border-color-light-1;
    }

    & .ant-modal-title {
        color: @text-color-light-8;
    }

    & .ant-modal-close {
        color: @text-color-light-4;
    }
    & .ant-menu-title-content .number,
    & .ant-drawer-body .number {
        color: @text-color-light-4;
    }

    & .ant-card-extra {
        color: @text-color-light-8;
    }

    & .card-header-time {
        color: @text-color-light-4;
    }

    & .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
    & .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
        color: @text-color-light-4;
    }

    & .ant-menu-item {
        & .ant-badge,
        & a {
            color: rgba(255, 255, 255, 0.65);
        }

        &:hover .ant-badge,
        &:hover a {
            color: rgba(255, 255, 255, 0.85);
        }
    }

    & .ant-divider {
        border-top: 1px solid rgba(255, 255, 255, 0.06);
    }

    & .custom-piechart {
        & .wrap {
            & .title {
              color: @text-color-light-6;
            }        
        }
    }

    & .custom-piechart-descripition {
        color: @text-color-light-4;
    
        & .ant-badge-status-text {
            color: @text-color-light-4;
        }
    }

    .alert-list-items {
        color: @text-color-light-4;

        & li + li::before {
            color: @border-color-light-1;
        }
    }

    /* 修改Chrome浏览器的滚动条样式 */
    ::-webkit-scrollbar {
        width: 5px; /* 设置滚动条宽度 */
        height: 5px;
        border-radius: 6px;
    }
  
    /* 滚动条滑块样式 */
    ::-webkit-scrollbar-thumb {
        background-color: #303942; /* 设置滑块颜色 */
        border-radius: 6px;
    }
  
    /* 滚动条背景样式 */
    ::-webkit-scrollbar-track {
        background-color: #24292d; /* 设置背景颜色 */
        border-radius: 6px;
    }
  
    /* 修改Edge浏览器的滚动条样式 */
    ::-ms-scrollbar {
        width: 4px; /* 设置滚动条宽度 */
        border-radius: 6px;
    }
  
    /* 滚动条滑块样式 */
    ::-ms-thumb {
        background-color: #303942; /* 设置滑块颜色 */
        border-radius: 6px;
    }
  
    /* 滚动条背景样式 */
    ::-ms-track {
        background-color: #24292d; /* 设置背景颜色 */
        border-radius: 6px;
    }

    & .ant-list {
        color: @text-color-light-8;
    }

    & .ant-list-item {
        color: @text-color-light-8;
    }

    & .app-notice {
        & span {
            color: @text-color-light-6;
        }
        
        & a {
            color: @text-color-light-6;
            &:hover,
            &:active {
                color: @text-color-light-8;
            }
        }
    }
      
    & .ant-input-group-addon {
        background-color: transparent;
        color: @text-color-light-4;
        border-color: rgba(255, 255, 255, 0.2);
    }

    & .ant-btn-primary[disabled],
    & .ant-btn-primary[disabled]:hover,
    & .ant-btn-primary[disabled]:focus,
    & .ant-btn-primary[disabled]:active {
        color: rgba(255, 255, 255, 0.25);
        border-color: rgba(255, 255, 255, 0.2);
        background: rgba(0, 0, 0, 0.05);
    }

    & .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        color: @text-color-light-4;
        background: rgba(0, 0, 0, 0.05) !important;
        border-color: rgba(255, 255, 255, 0.2) !important;
    }

    & .ant-input-number {
        background-color: transparent;
        color: @text-color-light-8;
        border-color: rgba(255, 255, 255, 0.2);
    }

    & .ant-input-number:hover:not(.ant-input-number-borderless) .ant-input-number-handler-down,
    & .ant-input-number-focused:not(.ant-input-number-borderless) .ant-input-number-handler-down {
        border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
    }

    & .ant-input-number-handler-wrap {
        background-color: transparent;
    }

    & .ant-input-number-handler,
    .ant-input-number-handler-up-inner,
    .ant-input-number-handler-down-inner {
        color: @text-color-light-4;
    }

    & .ant-input-number-handler {
        border-left: 1px solid rgba(255, 255, 255, 0.2);
    }

    & .ant-input-number-handler-down {
        border-top: 1px solid rgba(255, 255, 255, 0.2);
    }

    & .ant-input-number-handler-up:hover,
    & .ant-input-number-handler-down:hover {
        background-color: transparent;
    }

    & .ant-input-number-group-addon {
        background-color: transparent;
        color: @text-color-light-4;
        border-color: rgba(255, 255, 255, 0.2);
    }

    & .ant-input-number-disabled {
        background: rgba(0, 0, 0, 0.05) !important;
        color: @text-color-light-4;
    }

    & .ant-form-item-explain,
    & .ant-form-item-extra {
        color: @text-color-light-4;
    }

    & .ant-form-item-label > label .ant-form-item-tooltip {
        color: @text-color-light-4;
    }

    & .ant-picker-status-error.ant-picker,
    & .ant-picker-status-error.ant-picker:not([disabled]):hover {
        background-color: transparent;
    }

    & .ant-descriptions-bordered .ant-descriptions-item-label,
    & .ant-descriptions-bordered .ant-descriptions-item-content {
        border-right-color: rgba(255, 255, 255, 0.2);
    }

    & .ant-descriptions-bordered .ant-descriptions-row {
        border-bottom-color: rgba(255, 255, 255, 0.2);
    }

    & .ant-descriptions-bordered .ant-descriptions-item-label {
        background-color: transparent;
    }

    & .ant-descriptions-bordered .ant-descriptions-view {
        border-color: rgba(255, 255, 255, 0.2);
    }

    & .app-readonly-form .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
    & .app-readonly-form .ant-picker-input > input[disabled],
    & .app-readonly-form .ant-input-number-disabled {
        color: @text-color-light-8;
    }

    & .app-descriptions .ant-descriptions-item-content {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    & .recharts-tooltip-item-list > li {
        color: @text-color-light-8 !important;
    }

    & .ant-table-row-expand-icon {
        background: transparent;
        border-color: @text-color-light-2;
        &:hover,
        &:focus,
        &:active {
            color: @primary-color;
            border-color: @primary-color;
        }
    }

    & tr.ant-table-expanded-row > td,
    & tr.ant-table-expanded-row:hover > td {
        background: transparent;
    }

    & .ant-card-head-title > .anticon {
        color: @text-color-light-4;
    }

    & .btn-close {
        color: @text-color-light-4;
        &:active {
            color: @text-color-light-8;
        }
    }

    & .app-analysis-menu {
        color: @text-color-light-4;

        & ul {
            color: @text-color-light-8;
        }

        & .anticon-holder {
            color: @text-color-light-4;
        }
    }

    & .time-clock {
        color: rgba(255, 255, 255, 0.8);
    }

    & .ant-slider-mark-text {
        color: rgba(255, 255, 255, 0.3);
    }

    & .ant-slider-rail {
        background-color: rgba(255, 255, 255, 0.1);
    }

    & .ant-slider:hover .ant-slider-rail {
        background-color: rgba(255, 255, 255, 0.3);
    }

    & .ant-btn.ant-btn-background-ghost {
        color: @text-color-light-6;
        border-color: @text-color-light-6;
    }

    & .ant-input-clear-icon {
        color: @text-color-light-4;
    }

    & .ant-progress-text {
        color: @text-color-light-4;
    }

    & .ant-progress-inner {
        background-color: rgba(255, 255, 255, 0.1);
    }

    & .app-table-small a {
        color: rgba(255,255,255,.85);
    }

    & .ant-card-head-title > span {
        color: @text-color-light-4;
    }

    & .ant-dropdown-menu-item-icon {
        color: @text-color-light-8;
    }

    & .ant-progress-steps-item {
        background: rgba(255, 255, 255, 0.2);
    }

    & .app-charge-point .text p {
        color: @text-color-light-4;
    }

    & .app-charge-point .text .value {
        color: @text-color-light-8;
    }

    & .app-charge-point .text .ant-badge-status-text {
        color: @text-color-light-4;
    }

    & .ant-tag-processing {
        background:rgba(0, 156, 205, 0.1);
        border-color: rgba(0, 156, 205, 0.2);
    }

    & .app-dashboard-item-text-wrap {
        & .title {
            color: @text-color-light-6;
        }
        & .description {
            color: @text-color-light-4;
        }
    }

    & .app-dashboard-item-certificate-card {
        & .ant-card-body {
            &::before, &::after {
                border-color: rgba(255, 255, 255, 0.05);
            } 
        }
    }

    & .app-dashboard-item-certificate-item {
        & .description {
            color: @text-color-light-4;
        }
    }

    & .app-dashboard-item-certificate-note {
        border-color: rgba(255, 255, 255, 0.1);
    }

    & .ant-menu-dark .ant-menu-inline.ant-menu-sub {
        background-color: rgba(0, 0, 0, 0.05);
    }

    & .ant-table-cell-scrollbar:not([rowspan]) {
        box-shadow: none;
    }

    & .ant-menu-submenu {
        &.ant-menu-submenu-selected {
            & > .ant-menu-submenu-title {
                color: @primary-color;
                background-color: rgba(0, 156, 205, 0.1);
                border-radius: 6px;
                & > .ant-menu-title-content {
                    & > a {
                        color: @primary-color;
                    }
                }
                & .ant-menu-submenu-arrow::before,
                & .ant-menu-submenu-arrow::after {
                    background: @primary-color;
                }
            }
        }

        & .ant-menu-title-content {
            & > a {
                color: rgba(255,255,255,.65);
            }
        }
    }

    & .app-table-custom {
        & .anticon-calendar{
          color: @text-color-light-4;
        }
    }

    & .ant-steps-item-finish .ant-steps-item-icon,
    & .ant-steps-item-wait .ant-steps-item-icon {
        background-color: transparent;
    }

    & .ant-steps-item-wait .ant-steps-item-icon {
        border-color: @text-color-light-4;
    }

    & .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
        color: @text-color-light-4;
    }

    & .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: @text-color-light-8;
    }

    & .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: @text-color-light-8;
    }

    & .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: @text-color-light-4;
    }

    & .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
    & .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background-color: rgba(255, 255, 255, 0.2);
    }

    & .ant-upload.ant-upload-drag {
        background-color: rgba(255, 255, 255, 0.05);
        border-color: rgba(255, 255, 255, 0.1);
    }

    & .ant-upload.ant-upload-drag p.ant-upload-text {
        color: @text-color-light-8;
    }

    & .ant-upload.ant-upload-drag p.ant-upload-hint {
        color: @text-color-light-4;
    }

    & .ant-picker-separator {
        color: @text-color-light-4;
    }

    & .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number {
        background-color: transparent;
    }

    & .ant-picker-status-error.ant-picker {
        border-color: @error-color;
    }

    & .ant-result-title {
        color: @text-color-light-8;
    }

    & .ant-result-subtitle {
        color: @text-color-light-4;
    }

    &.ant-picker-dropdown {
        background-color: @background-color-exlightdark;
        color: @text-color-light-8;

        & .ant-picker-panel-container {
            background-color: @background-color-exlightdark;
            color: @text-color-light-8;
        }

        & .ant-picker-header {
            border-color: rgba(255, 255, 255, 0.1);
            color: @text-color-light-8;

            & button {
                color: @text-color-light-6;
            }
        }

        & .ant-picker-cell-in-view {
            color: @text-color-light-8;

            &.ant-picker-cell-range-start,
            &.ant-picker-cell-range-end {
                & .ant-picker-cell-inner:hover {
                    background-color: @primary-color;
                }
            }

            & .ant-picker-cell-inner:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }

        & .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
            background-color: rgba(255, 255, 255, 0.1);
        }

        & .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
        & .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
            background-color: transparent;
        }

        & .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
        & .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
        & .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
        & .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
        & .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
        & .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
        & .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
            background-color: rgba(0, 156, 205, 0.3);
        }

        & .ant-picker-panel {
            border-color: rgba(255, 255, 255, 0.1);
        }

        & .ant-picker-cell {
            color: @text-color-light-2;
        }

        & .ant-picker-content th {
            color: @text-color-light-4;
        }

        & .ant-picker-cell-in-view .ant-picker-cell-inner {
            color: @text-color-light-8;
        }

        & .ant-picker-cell-in-view.ant-picker-cell-disabled .ant-picker-cell-inner {
            color: @text-color-light-2;
        }

        & .ant-picker-panel .ant-picker-footer {
            border-top-color: rgba(255, 255, 255, 0.1);

            & .ant-picker-today-btn {
                color: @text-color-light-8;
            }
        }

        & .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start,
        & .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end {
            &::before {
                background-color: rgba(0, 156, 205, 0.3);
            }
            & .ant-picker-cell-inner {
                background-color: transparent;
            }
        }

        & .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
        & .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
        & .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
            background-color: rgba(0, 156, 205, 0.1);
        }

        & .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner {
            background-color: rgba(255, 255, 255, 0.1);
        }

        & .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
            background-color: transparent;
        }

        & .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
            background-color: rgba(0, 156, 205, 0.1);
        }

        & .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
            color: @text-color-light-8;
        }

        & .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }

        & .ant-picker-time-panel-column:not(:first-child),
        & .ant-picker-datetime-panel .ant-picker-time-panel {
            border-left: 1px solid rgba(255, 255, 255, 0.1);
        }

        & .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
            background-color: rgba(0, 156, 205, 0.1);
            border-color: rgba(0, 156, 205, 0.2);
        }

        & .ant-picker-range-arrow::before,
        & .ant-picker-range-arrow::after {
            background: @background-color-exlightdark;
        }

    }

    & .table-cell-border {
        border-left: 1px solid rgba(255, 255, 255, 0.05);
    }
}

