/* stylelint-disable selector-not-notation */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable no-descending-specificity */

/** side menu **/ 
.ant-layout-sider {
  background-color: #fff;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left,
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none;
}

// .app-aside {
//   background-color: @background-color-lightdark;

//   & .app-aside-menu {
//     background-color: @background-color-lightdark;
//   }
// }

.ant-layout-sider-trigger {
  font-size: 20px;
  background-color: #fff;
  color: #252525;
}

// .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
//   background-color: rgb(0 156 205 / 10%);
// }

// .app-aside-collapsed {
//   background-color: @primary-color;
//   border: 3px solid @background-color-dark;
// }

// .ant-menu-dark .ant-menu-item-selected > a,
// .ant-menu-dark .ant-menu-item-selected > span > a,
// .ant-menu-dark .ant-menu-item-selected > a:hover,
// .ant-menu-dark .ant-menu-item-selected > span > a:hover {
//   color: @primary-color;
// }

// .ant-menu-dark .ant-menu-item-selected,
// .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
// .ant-menu-dark .ant-menu-item-selected .anticon,
// .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
// .ant-menu-dark .ant-menu-item-selected .anticon + span {
//   color: @primary-color;
// }

// .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
//   background-color: rgb(0 156 205 / 10%);
// }

// .ant-menu-dark .ant-menu-item-selected,
// .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
// .ant-menu-dark .ant-menu-item-selected .anticon,
// .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
// .ant-menu-dark .ant-menu-item-selected .anticon + span {
//   color: @primary-color;
// }

/** ant **/
a {
  color: @primary-color;

  &:hover,
  &:focus {
    color: @primary-color-hover;
  }
}

.ant-btn-link {
  color: @primary-color;
}

.ant-card-bordered {
  border: 0;
}

.ant-menu-item {
  border-radius: @border-radius-base;
}

.ant-btn-icon-only {
  font-size: 2rem;

  & > * {
    font-size: 2rem;
  }
}

.ant-layout-sider-collapsed .app-logo {
  margin: 0 !important;
}

.ant-row + .ant-row {
  margin-top: 16px;
}

.ant-input-affix-wrapper > .ant-input {
  border: none;
}

.ant-checkbox-inner {
  background: none;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: @primary-color;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: none;
}

.ant-switch:focus {
  box-shadow: none;
}

.ant-layout-footer {
  padding: 12px 50px;
  background: none;
}

.ant-page-header {
  padding: 0;
  background-color: transparent;
}

.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 8px;
}

// 進度條主色
.ant-progress-success-bg, .ant-progress-bg {
  background-color: @primary-color;
}

.ant-dropdown-menu-item-icon {
  color: rgba(0, 0, 0, 0.85);
}

.ant-progress-steps-item-active {
  background: @primary-color !important;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  border-radius: @border-radius-base;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: @background-color-lightdark;
}

.ant-menu-submenu > .ant-menu {
  padding: 8px;
}